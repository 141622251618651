import React from 'react'
import Layout from '@components/layout/Layout'
import SiteLink from '@components/SiteLink'
import SiteMapComponent from '@components/site-map/site-map.component'
import './page-not-found.scss'

const PageNotFoundComponent = () => {

  const location = { pathname: "/404" }

  return (
    <Layout location={location}>
      <div className="page-not-found-wrapper primary-container">
        <h1>Page Not Found</h1>
        <p>
          <SiteLink to="/" className="link">
            Click here
          </SiteLink>{' '}
          to go to the Narcolepsy Link Home page, or{' '}
          <SiteLink to="/stay-connected/about-narcolepsy-link/" className="link">
            click here
          </SiteLink>{' '}
          to view our About page.
        </p>
        <SiteMapComponent />
      </div>
    </Layout>
  )
}

export default PageNotFoundComponent
